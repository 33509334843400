@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

:root {
    font-family: 'poppins';
    color: var(--textPrimary);
}

body {
    margin: 0;
    background-color: var(--backgroundPrimary);
}

html {
    --textPrimary: white;
    --textSecondary: #5CE1E6;
    --textTertiary: #FF8484;
    --backgroundPrimary: #263547;
    --backgroundSecondary: #354564;
    --whiteButtonFill: rgba(255, 255, 255, 0.3);
    --blurpleButtonFill: rgba(38, 53, 71, 0.3);
    --slightlyTransparentBlue: rgba(92, 225, 230, 0.8);
    --tocBackground: rgba(0, 0, 0, 0.94);

    --outerPadding: 24px;

    --maxWidth: 692px;
    --aboutOffsetMaxWidth: 324px;
    --experienceMaxWidth: 500px;
    --experienceLeftShift: 82px;
    --skillsMaxWidth: 274px;
    --tocOffsetTop: 56px;
    --tocOffsetLeft: 64px;
    --tocButtonLeft: 24px;
    --tocButtonTop: 24px;
    --continueButtonWidth: 56px;

    --projectImagePercentage: 68%;
    --projectTextPercentage: calc(100% - var(--projectImagePercentage));

    --borderRadius: 8px;

    --tiniestSpacer: 4px;
    --tinierSpacer: 8px;
    --tinySpacer: 12px;
    --smallSpacer: 18px;
    --spacer: 24px;
    --largeSpacer: 48px;
    --largerSpacer: 64px;
    --hugeSpacer: 180px;

    --fontSmall: 1rem;
    --fontMedium: 1.3rem;
    --fontSmallHeader: 1.3rem;
    --fontHeader: 1.5rem;
    --fontButton: 1.4rem;
    --fontIcon: 2rem;
    --fontHuge: 3.4rem;

    --tabletBreakPoint: 1024px;
}

h1,
h2,
h3,
h4,
p {
    margin: 0;
}

h3 {
    color: var(--textTertiary);
    font-weight: 700;
    font-size: var(--fontSmall);
    letter-spacing: 0.06em;
    text-align: center;
    margin-bottom: 12px;
}

h4 {
    color: var(--textSecondary);
    font-weight: 500;
    font-size: var(--fontHeader);
    letter-spacing: 0.06em;
}

p {
    font-style: normal;
    font-weight: 300;
    font-size: var(--fontSmall);
    letter-spacing: 0.06em;
}

.button {
    margin: 0;
    padding: 0;
    border-radius: var(--borderRadius);
    font-family: 'poppins';
    color: var(--textPrimary);
}

button:hover,
a:hover {
    transform: scale(1.04);
}

button,
a {
    cursor: pointer;
}

@media (max-width: 1024px) {
    html {
        --maxWidth: 100%;
    }
}

@media (max-width: 664px) {
    html {
        --fontSmall: 1rem;
        --fontMedium: 1.1rem;
        --fontSmallHeader: 1rem;
        --fontHeader: 1.2rem;
        --fontButton: 1.4rem;
        --fontHuge: 2.5rem;

        --projectImagePercentage: 100%;
        --projectTextPercentage: 100%;

    }
}